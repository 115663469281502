import * as React from 'react';
import { SmallHero } from "../components/global_component/small_hero/SmallHero"
import { TitleSubtext } from "../components/delivery/title_subtext/TitleSubtext"
import { InfoBanner } from "../components/delivery/delivery-info-banner/InfoBanner"
import { MapRegions } from "../components/delivery/map-regions/MapRegions"
import { LargeBanner } from "../components/global_component/large_banner/LargeBanner"
import { getImage, withArtDirection } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import Seo from "../components/layout/Seo"

const LocationPage = (props) => {

    const data = useStaticQuery(graphql`
    {
      allWpPage(filter: {id: {eq: "cG9zdDo0MjE="}}) {
            nodes {
              template {
                seoMetaTags {
                  seoMetaTagsDescription
                  seoMetaTagsJsonShema
                  seoMetaTagsTitle
                }
              }
              delivery {
                deliveryBanner {
                  deliveryBannerTitle
                  deliveryBannerImg {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                deliveryLargeOrderBanner {
                  deliveryLargeOrderBannerTitle
                  deliveryLargeOrderBannerText
                  deliveryLargeOrderBannerImgMobile {
                    localFile {
                      childImageSharp {
                        gatsbyImageData 
                      }
                    }
                  }
                  deliveryLargeOrderBannerImg {
                    localFile {
                      childImageSharp {
                        gatsbyImageData 
                      }
                    }
                  }
                  deliveryLargeOrderBannerButton {
                    url
                    title
                    target
                  }
                }
              }
            }
          }
    }
  `)

    const seoMetaPost = props.pageContext?.seoMetaPost
    let  schema = props?.pageContext?.locationpost?.schemaorg || " ";

    try{
        schema = JSON.parse( schema)
    } catch {

        console.log(' wrong schema!!!', schema , ' wrong schema!!!');
        schema= "  "
    }
    const seo = {
        title: seoMetaPost?.seoMetaPostTitle,
        description: seoMetaPost?.seoMetaPostDescription,
        schemaMarkup : schema
    }

    const title = props.pageContext?.title
    const banner = data?.allWpPage?.nodes[0]?.delivery?.deliveryBanner?.deliveryBannerImg?.localFile?.childImageSharp?.gatsbyImageData
    const titleLargeBanner = data.allWpPage?.nodes[0]?.delivery?.deliveryLargeOrderBanner?.deliveryLargeOrderBannerTitle
    const textLargeBanner = data.allWpPage?.nodes[0]?.delivery?.deliveryLargeOrderBanner?.deliveryLargeOrderBannerText
    const butonLargeBanner = data.allWpPage?.nodes[0]?.delivery?.deliveryLargeOrderBanner?.deliveryLargeOrderBannerButton
    const largeBanner = data?.allWpPage?.nodes[0]?.delivery?.deliveryLargeOrderBanner
    const description = props.pageContext?.locationpost?.locationpostDescription
    const images = withArtDirection(getImage(largeBanner.deliveryLargeOrderBannerImg?.localFile), [
    {
        media: "(max-width: 768px)",
        image: getImage(largeBanner.deliveryLargeOrderBannerImgMobile?.localFile)
    }
])


    return(
    <>
        <Seo
          {...seo}
        />
        <SmallHero
          title={title + "  Cannabis Delivery "}
          banner={banner}
        />
        <TitleSubtext
            hideTitle
          description={description}
        />
        <LargeBanner
          images={images}
          titleLargeBanner={titleLargeBanner}
          textLargeBanner={textLargeBanner}
          butonLargeBanner={butonLargeBanner}
        />
        <MapRegions />
        <InfoBanner />
    </>
    )
}

export default LocationPage;
